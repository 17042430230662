<template>
  <div>
    <article class="main-content">

      <OrderedComponents
        :data="home"
      />

    </article>
  </div>
</template>

<script setup>
import { mainDynamic } from '~/graphql/homeQuery'
const { data } = await useAsyncQuery(mainDynamic)

let home = {}

const metaTitle = ref('')
const metaDescription = ref('')

useHead({
  title: metaTitle,
  meta: [ { name: 'description', content: metaDescription } ]
})

if (data.value?.glavnayaDinamicheskaya) {
  home = handleStrapiItem(data.value.glavnayaDinamicheskaya.data).Blocks
  metaTitle.value = data.value.glavnayaDinamicheskaya.data.attributes.metatitle || 'Ростелеком Контакт-Центр'
  metaDescription.value = data.value.glavnayaDinamicheskaya.data.attributes.metadescription || ''
}

</script>
