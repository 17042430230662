import gql from 'graphql-tag'

export const mainDynamic = gql`
query main {
  glavnayaDinamicheskaya {
    data {
      id
      attributes {
        metatitle
        metadescription
        Blocks {
          __typename 
          ...on ComponentMainpageBlokOblozhkaNaGlavnoj {
            id
            title
            description
            background {
              data {
                attributes {
                  url
                }
              }
            }
            video {
              data {
                attributes {
                  url
                }
              }
            }
          }
          ...on ComponentMainpageBlokDostizheniya {
            id
            achivements {
              data {
                id
                attributes {
                  title
                  description
                  
                }
              }
            }
          }
          ...on ComponentMainpageBlokIndustrii {
            id
            industrii {
              id
              header
              description
              tile {
                data {
                  id
                  attributes {
                    type
                    cards {
                      data {
                        id
                        attributes {
                          link
                          title
                          image {
                            data {
                              id
                              attributes {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentMainpageBlokCzifrovyeProduktyIUslugi {
            id
            title
            description
            products {
              data {
                id
                attributes {
                  background {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  name
                  type
                  link
                  icon {
                    data {
                      id
                      attributes {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentMainpagePopulyarnyeStraniczy {
            id
            title
            tileset {
              data {
                id
                attributes {
                  tile_type
                  cards {
                    ...on ComponentMainpagePopulyarnyeStraniczySsylki {
                      link
                      title
                      background {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksBlokNamDoveryayut {
            id
            title
            description
            logos {
              data {
                id
                attributes {
                  name
                  image {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  imagecolor {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentMainpageNovosti {
            id
            title
            link_more_caption
            link_more_url
            news {
              data {
                attributes {
                  slug
                  title
                  published
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ...on ComponentBlocksRazdelitelBlokov {
            id
            paddingtop
            paddingbottom
            fullwidth
            visible
          }
          ...on ComponentBlocksFormaOsnovnaya {
            id
            tipy_forms {
              data {
                id
                attributes {
                  reason
                  formSubtitle
                  formFields {
                    data {
                      attributes {
                        type
                        label
                        errorMessage
                        selectList {
                          data {
                            attributes {
                              clientName
                              systemId
                            }
                          }
                        }
                      }
                    }
                  }
                  siteFormTitle
                  url
                  description
                  linkTitle
                  legal {
                    data {
                      attributes {
                        legalText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`